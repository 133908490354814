import {useEffect} from "react";
import {useActor} from "@xstate/react";
import { useNavigate } from 'react-router-dom'
import {Header} from "../modules/Header/Header";
import {Hero} from "../modules/Hero/Hero";
import {Footer} from "../modules/Footer/Footer";
import {About} from "../modules/About/About";
import {Prizes} from "../modules/Prizes/Prizes";
import {MoreBonuses} from "../modules/MoreBonuses/MoreBonuses";
import {FAQ} from "../modules/FAQ/FAQ";
import {AuthState} from "@/js/xstate/auth.machine";


export function Home() {
    const [stateAuth, sendAuth] = useActor(AuthState);
    const navigate = useNavigate()

    useEffect(() => {
        document.title = "Главная страница | Программа лояльности SK ZIC";
    }, []);

    useEffect(() => {
        // setTimeout(() => {
            if (!localStorage.getItem("sessionID")) {
                navigate('/auth')
            }
        // }, 1000)
    }, [])

    return (<>
        <Header dark />
        <main id="main" role="main">
            <Hero/>
            <About/>
            {stateAuth.context.type === 'seller' ? 
                <>
                    <Prizes/>
                    {/* <MoreBonuses/>  */}
                </> :
                <></>
            }
            <FAQ/>
        </main>
        <Footer/>
    </>)
}